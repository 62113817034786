@use autoprefixer;

.sponsor-wrap {
    .ant-col {
        transition: opacity 0.3s;
        cursor: pointer;
        // &::before {
        //   content: "";
        //   width: 15px;
        //   height: 24px;
        //   background-color: @theme;
        //   position: absolute;
        //   right: 80px;
        //   bottom: -8px;
        //   opacity: 0;
        //   transition: opacity .2s;
        // }
    }

    .ant-col:hover {
        opacity: 0.8;

        &::before {
            opacity: 1;
        }
    }

    .sponsor {
        border-radius: 15px;
        overflow: hidden;
    }

    img {
        width: 100%;
    }
}

.ant-tabs.ant-tabs-card.home-promotion-wrap {
    overflow: visible;

    &._2 {
        .ant-tabs-nav-wrap {
            justify-content: flex-start !important;
        }
        .ant-tabs-nav-container {
            height: 120px;

            .ant-tabs-tab {
                height: 120px;
            }
        }
    }

    .ant-tabs-bar {
        height: 100px;
        border-width: 0;
    }

    .ant-tabs-nav-container {
        height: 100px;
        padding: 0;
        font-size: 1.5em;
        overflow: visible;

        .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
        .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
            top: -2.2em;
            height: 40px;

            &.ant-tabs-tab-prev {
                left: 94%;
            }

            .ant-tabs-tab-next-icon,
            .ant-tabs-tab-prev-icon {
                width: 22px;
                height: 22px;
                border: 2px solid #666;
                color: #666;
                border-radius: 50%;
                line-height: 16px;
                font-weight: bold;

                .ant-tabs-tab-prev-icon-target,
                .ant-tabs-tab-next-icon-target {
                    font-size: 14px;
                }
            }

            &:not(.ant-tabs-tab-btn-disabled) {
                .ant-tabs-tab-next-icon:hover,
                .ant-tabs-tab-prev-icon:hover {
                    border-color: #111;
                    color: #111;
                }
            }
        }

        .ant-tabs-tab {
            height: 100px;
            background: transparent;
            border: 0;
            padding: 0;
            transition: none;

            .home-sponsor {
                border-radius: 15px;
                overflow: hidden;
                // box-shadow: 4px 6px 4px #ddd;
                margin-right: 20px;

                img {
                    // 减少1px避免出现左右滚动
                    height: 119px;
                    max-width: 540px;
                }
            }

            &:last-child .home-sponsor,
            &:last-child .home-promotion {
                margin-right: 0;
            }
        }
    }

    .ant-tabs-nav-scroll {
        padding-top: 4px;
    }

    &.sponsor .ant-tabs-bar,
    &.sponsor .ant-tabs-nav-container,
    &.sponsor .ant-tabs-nav-container .ant-tabs-tab {
        height: 120px;
    }
}

.home-split-page-btn-wrap {
    position: absolute;
    width: 99%;
    top: 0;
    height: 30px;

    .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
    .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
        position: absolute;
        top: 0;
        height: 30px;

        &.ant-tabs-tab-prev {
            left: 94%;
        }

        .ant-tabs-tab-next-icon,
        .ant-tabs-tab-prev-icon {
            width: 22px;
            height: 22px;
            border: 2px solid #666;
            color: #666;
            border-radius: 50%;
            line-height: 16px;
            font-weight: bold;

            .ant-tabs-tab-prev-icon-target,
            .ant-tabs-tab-next-icon-target {
                font-size: 14px;
            }
        }

        &:not(.ant-tabs-tab-btn-disabled) {
            .ant-tabs-tab-next-icon:hover,
            .ant-tabs-tab-prev-icon:hover {
                border-color: #111;
                color: #111;
            }
        }
    }
}

.ant-tabs.ant-tabs-line.home-split-page-promotion-wrap {
    padding-top: 4px;
    margin-top: -4px;

    .ant-tabs-bar {
        display: none;
    }

    .ant-tabs-tabpane {
        white-space: nowrap;
    }

    .home-promotion {
        width: 480px;
        display: inline-block;
        border-radius: 50px;
        overflow: hidden;
        // box-shadow: 4px 6px 4px #ddd;
        margin-right: 20px;
        cursor: pointer;
        img {
            height: 100px;
        }
    }
}

/* 健康博彩 从小同开始 */
h1.title {
    margin: 0;
    font-size: 1.8em;
    font-weight: bold;
    line-height: 44px;
    padding-left: 0px;
    color: #222;
}

.app-qrcode-wrap {
    background-color: #00a6ff;
    color: #fff;
    height: 240px;
    margin-top: 162px !important;
    margin-bottom: 102px !important;
    font-size: 12px;
    text-align: center;
    .copy-icon {
        padding-left: 10px;
        padding-bottom: 5px;
    }
    .common-distance {
        padding: 0;
    }
    .homeinstallurl {
        cursor: pointer;
        white-space: normal;
        word-break: break-all;
        width: 322px;
        overflow: auto;
    }

    .app-picture {
        width: 100%;
        height: 240px;
        background-image: url("/cn/img/home/part/app-download-3.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;
        .AppDownloadSection{
            position: absolute;
            top: -78px;
        }
        img {
            margin-top: -18%;
        }
    }

    .qrcode-picture {
        margin-top: 25px;
        height: 175px;
        width: 175px;
        background-image: url("/cn/img/home/part/qrcode base@2x.png");
        background-repeat: no-repeat;
        position: relative;
        background-size: cover;
        canvas {
            border: 15px solid #fff;
            margin-left: 2px;
            margin-top: 2px;
            border-radius: 20px;
        }

        & > img {
            width: 166px;
            height: 166px;
            line-height: 166px;
            border: 1px solid #1d1d1d;
        }

        & > p {
            font-size: 12px;
            position: absolute;
            left: 56px;
            bottom: 0;
            margin-bottom: 0.28em;
        }
        .text2 {
            left: 32px;
        }
    }

    .qrcode {
        h1 {
            position: absolute;
            top: -44px;
            left: 0px;
        }

        .qrcode-article-wrap {
            font-size: 14px;
            line-height: 1.8;
            text-align: left;
            h4 {
                color: #fff;
                margin: 0;
            }
            .app-down-icons {
                display: block;
                width: 70px;
                height: 30px;
                background-position: -51px -81px;
                background-repeat: no-repeat;
                margin-bottom: 10px;
            }
            .qrcode-article {
                color: #ffffff;
                padding-top: 35px;
                padding-bottom: 5px;
            }

            & > div {
                color: #ffee00;
                font-size: 16px;
                text-decoration: underline;
                line-height: 1.2;
                overflow: hidden;
                width: 100%;

                .anticon {
                    margin-left: 6px;
                }
            }
        }
    }
}

/* 8号商城 */
.eight-shoping {
    padding-top: 20px;
    margin-bottom: 38px;
    text-align: center;

    h1 {
        margin-top: 70px;
        margin-bottom: 15px;
    }

    .shop-brief {
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 20px;
        text-align: left;
    }

    .eight-shop-list a {
        margin-bottom: 15px;
        display: inline-block;
        border-radius: 10px;
        box-shadow: 4px 6px 4px #ddd;
        overflow: hidden;
    }
}

// Home Banner
// #t_banner_wrapper {
// 	// &.tlc-banner-list > div > div:nth-child(1n) .tlc-banner-item {
// 	// 	background-color: #feb624;
// 	// }
// 	// &.tlc-banner-list > div > div:nth-child(2n) .tlc-banner-item {
// 	// 	background-color: #6dd400;
// 	// }
// 	// &.tlc-banner-list > div > div:nth-child(3n) .tlc-banner-item {
// 	// 	background-color: #0091ff;
// 	// }
// 	// &.tlc-banner-list {
// 	// 	position: relative;
// 	// 	width: 1120px;
// 	// 	min-width: 1120px;
// 	// 	height: 400px;
// 	// 	line-height: 400px;
// 	// 	top: 0;
// 	// 	left: 0;
// 	// 	overflow: hidden;
// 	// 	.common-distance{
// 	// 		width: 1120px;
// 	// 		margin: auto;
// 	// 		padding:0
// 	// 	}
// 	// }

// 	.tlc-banner-item {
// 		width: 1120px!important;
// 		height: 400px;
// 		line-height: inherit;
// 		text-align: center;
// 		color: #fff;
// 		border-radius: 10px;
// 		overflow: hidden;
// 		box-sizing: border-box;
// 		color: transparent;
// 		overflow: hidden;
// 		background-color: #ebebeb;
// 		margin: auto;
// 		display: block!important;
// 	}

// 	.tlc-banner-item.pointer {
// 		cursor: pointer;
// 	}

// 	.tlc-banner-item img {
// 		height: 100%;
// 		vertical-align: top;
// 		border-radius: 10px;
// 	}

// 	.tlc-carousel-controller {
// 		position: absolute;
// 		height: 0;
// 		top: 0;
// 		left: 0;
// 		right: 0;
// 		margin: auto;
// 		z-index: 5;
// 	}

// 	.slide-arrow {
// 		width: 40px;
// 		height: 80px;
// 		position: absolute;
// 		top: 152px;
// 		outline: none;
// 		border-width: 0;
// 		border-radius: 4px;
// 		cursor: pointer;
// 		background-color: transparent;
// 		transition: background-color .3s;
// 	}

// 	.slide-arrow:hover {
// 		background-color: rgba(255, 255, 255, .2);
// 	}

// 	.slide-prev {
// 		left: 30px;
// 		background-image: url("/cn/img/home/part/arrow-to-left.png");
// 	}

// 	.slide-next {
// 		right: 30px;
// 		background-image: url("/cn/img/home/part/arrow-to-right.png");
// 	}

// 	.slide-list {
// 		width: 93%;
// 		height: 4px;
// 		position: absolute;
// 		text-align: center;
// 		top: 370px;
// 		white-space: nowrap;
// 		overflow: hidden;
// 	}

// 	.slide-item {
// 		display: inline-block;
// 		margin: 0 5px;
// 		width: 50px;
// 		height: inherit;
// 		background-color: #fff;
// 		opacity: 0.3;
// 		transition: opacity .3s;
// 		text-indent: -999999px;
// 	}

// 	.slide-item.slide-item-active {
// 		opacity: 1;
// 	}
// }

#game_banner {
    &.tlc-banner-list {
        position: relative;
        height: 150px;
        line-height: 150px;
        top: 0;
        left: 0;
    }

    .tlc-banner-item {
        display: flex;
        width: 500px;
        height: 161px;
        line-height: inherit;
        text-align: center;
        color: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        color: transparent;
        letter-spacing: -9999px;
        overflow: hidden;
    }

    .tlc-banner-item .img-left {
        width: 30%;
    }

    .tlc-banner-item .txt-right h3 {
        font-weight: bold;
    }

    .tlc-banner-item .txt-right {
        align-items: flex-start;
        padding: 15px;
        justify-content: flex-start;
        line-height: 1;
        letter-spacing: 0;
        display: flex;
        flex-direction: column;
        width: 70%;
        color: #000;
    }

    .tlc-banner-item .txt-end {
        font-size: 25px;
        padding: 30px 5px;
        letter-spacing: 1px;
    }

    .tlc-banner-item img {
        height: 100%;
        width: 100%;
        vertical-align: top;
        border-radius: 10px;
    }

    .tlc-carousel-controller {
        position: absolute;
        height: inherit;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 5;
    }

    .slide-arrow {
        width: 40px;
        height: 80px;
        position: absolute;
        top: 25%;
        outline: none;
        border-width: 0;
        border-radius: 4px;
        cursor: pointer;
        background-color: #ffffff40;
        transition: background-color 0.3s;
    }

    .slide-arrow:hover {
        background-color: #00000080;
    }

    .slide-prev {
        left: -40px;
        background-image: url("/cn/img/home/part/arrow-left.svg");
        opacity: 0.8;
    }

    .slide-next {
        right: -40px;
        background-image: url("/cn/img/home/part/arrow-right.svg");
        opacity: 0.8;
    }

    .slide-list {
        width: 100%;
        height: 4px;
        position: absolute;
        text-align: center;
        bottom: 20px;
    }

    .slide-item {
        display: inline-block;
        margin: 0 5px;
        width: 50px;
        height: inherit;
        background-color: #fff;
        opacity: 0.3;
        transition: opacity 0.3s;
        text-indent: 99999px;
    }

    .slide-item.slide-item-active {
        opacity: 1;
    }
}

/* 合作伙伴 */
.cooperate-wrap {
    .cooperate-content {
        width: 176px;
        height: 410px;
        line-height: 238px;
        padding: 120px 0;
        background-repeat: no-repeat;
        background-position: center 115px;
        text-align: center;
        &._1 {
            background-image: url("/cn/img/home/part/NUFC.png");
        }
        &._2 {
            background-image: url("/cn/img/home/part/SPURS.png");
        }
        p {
            display: inline-block;
            text-align: center;
            vertical-align: bottom;
            line-height: 26px;
            height: 26px;
            & > span {
                display: block;
            }
        }
        .cooperate-name {
            color: #00a6ff;
            font-size: 16px;
            font-weight: bold;
        }
    }
    .cooperate-people {
        width: 808px;
        height: 410px;
        background-size: 100% 100%;
        background-image: url("/cn/img/home/part/cooperate-people_big.webp");
        background-repeat: no-repeat;
        background-position: bottom;
    }
}

// Home Banner
#t_banner_wrapper {
    &.tlc-banner-list > div > div:nth-child(1n) .tlc-banner-item {
        background-color: #feb624;
    }
    &.tlc-banner-list > div > div:nth-child(2n) .tlc-banner-item {
        background-color: #6dd400;
    }
    &.tlc-banner-list > div > div:nth-child(3n) .tlc-banner-item {
        background-color: #0091ff;
    }
    &.tlc-banner-list {
        position: relative;
        height: 400px;
        line-height: 400px;
        top: 0;
        left: 0;
    }
    .tlc-banner-item {
        width: 1120px;
        height: 400px;
        line-height: inherit;
        text-align: center;
        color: #fff;
        border-radius: 10px;
        overflow: hidden;
        box-sizing: border-box;
        color: transparent;
        overflow: hidden;
        background-color: #ebebeb;
    }
    .tlc-banner-item.pointer {
        cursor: pointer;
    }
    .tlc-banner-item img {
        height: 100%;
        vertical-align: top;
        border-radius: 10px;
    }
    .tlc-carousel-controller {
        position: absolute;
        height: 0;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 5;
    }
    .slide-arrow {
        width: 40px;
        height: 80px;
        position: absolute;
        top: 152px;
        outline: none;
        border-width: 0;
        border-radius: 4px;
        cursor: pointer;
        background-color: #00000040;
        transition: background-color 0.3s;
    }
    .slide-arrow:hover {
        background-color: #00000080;
    }
    .slide-prev {
        left: 60px;
        background-image: url("/cn/img/home/part/arrow-to-left.png");
    }
    .slide-next {
        right: 60px;
        background-image: url("/cn/img/home/part/arrow-to-right.png");
    }
    .slide-list {
        width: 93%;
        height: 4px;
        position: absolute;
        text-align: center;
        top: 370px;
        white-space: nowrap;
        overflow: hidden;
    }
    .slide-item {
        display: inline-block;
        margin: 0 5px;
        width: 50px;
        height: inherit;
        background-color: #fff;
        opacity: 0.3;
        transition: opacity 0.3s;
        text-indent: -999999px;
    }
    .slide-item.slide-item-active {
        opacity: 1;
    }
}

.Right-Login-Action {
    display: flex;
    align-items: center;
    .BtnList {
        display: flex;
        width: 185px;
        justify-content: space-between;
        div {
            cursor: pointer;
        }
    }
}

.App-Qrcode-List {
    display: flex;
    padding-top: 10px;
    .App-Name {
        color: white;
        text-align: center;
    }
}
// 註冊成功彈窗
#register-success {
    background: #daffe3 0% 0% no-repeat padding-box !important;
    border-radius: 64px !important;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    width: 274px;
    height: 56px;
    font: normal normal normal 15px/24px Microsoft YaHei;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
    .Toastify__toast-body {
        .Toastify__zoom-enter {
            display: none;
        }
        .custom-toast {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 24px;
                height: 24px;
            }
        }
        span {
            margin-left: 15px;
            font: normal normal normal 16px/21px Microsoft YaHei;
            letter-spacing: 0px;
            color: #0ccc3c;
            opacity: 1;
            white-space: nowrap;
        }
    }
}

// 登入成功彈窗
#login-success {
    background: #daffe3 0% 0% no-repeat padding-box !important;
    border-radius: 64px !important;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    width: 238px;
    height: 56px;
    font: normal normal normal 15px/24px Microsoft YaHei;
    letter-spacing: 0px;
    color: #222222;
    opacity: 1;
    margin: 0 auto;
    .Toastify__toast-body {
        .Toastify__zoom-enter {
            display: none;
        }
        .custom-toast {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 24px;
                height: 24px;
            }
        }
        span {
            margin-left: 15px;
            font: normal normal normal 16px/21px Microsoft YaHei;
            letter-spacing: 0px;
            color: #0ccc3c;
            opacity: 1;
            white-space: nowrap;
        }
    }
}

.promotionBanner {
    .ant-tabs-tab-btn-disabled {
        display: none;
    }
}

.BannerBox {
    span {
        display: block !important;
    }
}


.GameReferImg{
    .GameName{
        display: none !important;
    }
}